import React from "react"

function Home(){
    return (
        <div id="home">
        <h1> Full Stack Software Engineer</h1>
        <p><b>Frontend Technologies:</b> HTML, CSS, Bootstrap, Javascript, React, Redux</p>
        <p><b>Backend Technologies:</b> Python, Flask, SQL, AWS</p>
        <a href="https://www.linkedin.com/in/justin-bigishiro/" target="_blank" rel="noopener noreferrer">
            Let's Connect<span role="img" aria-label="handshake">🤝</span></a>
        </div>
    )
}

export default Home
import React from "react"

function Resume(){
    return (
        <div id="resume">
            <h1>About</h1>
            <p>I am a passionate full-stack software engineer with expertise in front-end technologies like HTML, CSS, JavaScript, and React, as well as back-end technologies including Python, SQL, and Flask. I excel at building responsive and user-friendly web applications that seamlessly integrate with databases</p>
            <h1>Education</h1>
            <h3>Flatiron School, Online,USA</h3>
            <p>Certificate, Software Development</p>
            <p>Feb 2023 - Oct 2023</p>
            <h3>Tarrant County College</h3>
            <p>Associate's Degree, Electronics Technology</p>
            <p>Jan 2018 - May 2020</p>
            <h1>Experience</h1>
            <p>Amazon Fulfilment Center </p>
            <p>June 2016 - Present</p>
            <ul>
              <li>Ensure product quality and customer satisfaction by sorting and packing accurately.</li>  
              <li>Reduce product waste by investigating and resolving defects on products.</li>  
              <li>Consistently received positive feedback after training new hires on the procedures and tools used at Damageland.</li>  
            </ul>
            <h1>Skills</h1>
            <h3>Professional Skills</h3>
             <ul>
                <li>Problem Solving</li>
                <li>Team work</li>
                <li>Attention to detail</li>
                <li>Collaboration</li>
             </ul>
            <h3>Technical Skills</h3>
            <ul id="technical">
                <li>HTML</li>
                <li>CSS</li>
                <li>Bootstrap</li>
                <li>Javascript</li>
                <li>React</li>
                <li>Python</li>
                <li>Flask</li>
                <li>SQL</li>
                <li>AWS</li>
             </ul>

        </div>
        
    )
}

export default Resume
import React from "react"

function Blogs(){
    return (
        <div>
        <h1>Blogs</h1>
        <p>1. Ensuring Data Integrity:
            Constraints and Validations in Python Flask &nbsp; 
            <a href="https://dev.to/jbigishiro/ensuring-data-integrity-constraints-and-validations-in-python-flask-55oj" target="_blank" rel="noopener noreferrer">Click here to read</a></p>
        <p>2. Table JOIN in SQL &nbsp; <a href="https://dev.to/jbigishiro/table-join-in-sql-oeo" target="_blank" rel="noopener noreferrer">Click here to read</a></p>
        <p>3. Mastering Event Listeners in React &nbsp; <a href="https://dev.to/jbigishiro/mastering-event-listeners-in-react-2jp2" target="_blank" rel="noopener noreferrer">Click here to read</a></p>
        <p>4. Making Decisions: IF...ELSE statement. &nbsp; <a href="https://dev.to/jbigishiro/making-decisons-ifelse-statement-1g5d" target="_blank" rel="noopener noreferrer">Click here to read</a></p>
        </div>
        
    )
}

export default Blogs
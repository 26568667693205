import React from "react"

function Projects(){
    return (
        <div id="projects">
          <h1>Projects</h1>
          <h2>1. Number Search Game</h2>
          <p>In this game, the numbers are ordered in random order each time you start a new game. 
            <a href="https://github.com/jbigishiro/Number-Search-" target="_blank" rel="noopener noreferrer">Github Link</a>
            </p>
          
          <h2>2. Supplier App</h2>
          <p>This project is a command line application built using python that displays 
            the products information and their suppliers.
            <a href="https://github.com/jbigishiro/Phase3-python-CLI-project-Supplier-App" target="_blank" rel="noopener noreferrer">Github Link</a>
          </p> 

          <h2>3. Greatest Scientists</h2>
          <p>This is a single page application (SPA) that teaches about some of great scientists that existed in the past. 
            Their countries of origin , their field, and the time they lived are also present in this app. 
            <a href="https://github.com/jbigishiro/phase-2-project-greatest-scientists" target="_blank" rel="noopener noreferrer">Github Link</a>
            </p>

            <h2>4. Cow Market App</h2>
            <p>In this app, the farmers can sell and buy cows. <a href="https://github.com/jbigishiro/Phase-1-Project-Cow-Market" target="_blank" rel="noopener noreferrer">Github Link</a> </p>
   
            <h2>5. Portfolio Page</h2>
            <p>This is my portfolio web. <a href="https://github.com/jbigishiro/Portfolio/tree/master" target="_blank" rel="noopener noreferrer">Github Link</a> </p>
        </div> 
        

    )
}

export default Projects
import React from "react"

function Contact(){
    return (
        <div>
            <h1>Contact </h1>
            <p>Fort Worth, TX 76120</p>
            <p><span role="img" aria-label="telephone">📞</span> 817-525-3308</p>
            <p><span role="img" aria-label="nessage">📧</span> justin.rvd@gmail.com</p>
            <p><a href="https://www.linkedin.com/in/justin-bigishiro/" target="_blank" rel="noopener noreferrer">Linkedin</a></p>
        </div>
        
    )
}

export default Contact